import { useEffect } from 'react'
// next
import { useRouter } from 'next/router'
// config
import { PATH_AFTER_LOGIN } from '../config'
// routes
import { PATH_DASHBOARD } from '@/routes/paths'
import { useCurrentRouterLocale } from '@slc/hooks'

// ----------------------------------------------------------------------
Index.authRequired = true

export default function Index() {
  const router = useRouter()
  const { pathname } = router

  const locale = useCurrentRouterLocale()

  const page = `/${locale}${PATH_AFTER_LOGIN}`
  console.log('** Index --> ', page)
  useEffect(() => {
    if (pathname === PATH_DASHBOARD.root) {
      router.replace(page)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {
    router.prefetch(page)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
